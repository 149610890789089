import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { Expo } from "gsap/all"
import { SplitText } from "gsap/SplitText"
import { useInView } from "react-hook-inview"
import { easeOut, longAnim } from "utils/gsapAnims"
import { separateChildrenFromLine } from "utils/separateChildTagsFromLine"
import { renderClasses } from "utils/renderClasses"
import Title from "components/elements/Title"
import Image from "./Image"
import Eyebrow from "./Eyebrow"
import Text from "./Text"
import Action from "./Action"
import "./card.scss"

const Card = ({
  action,
  eyebrow,
  hAlign = "left",
  media,
  removeLine = false,
  shouldAnimateIn = true,
  text,
  title,
  vAlign = "top",
}) => {
  const [ref, inView] = useInView({ threshold: 0.7, unobserveOnEnter: true })
  const [hasEnteredViewport, setHasEnteredViewport] = useState(false)
  React.useEffect(() => {
    inView && setHasEnteredViewport(true)
  }, [inView, setHasEnteredViewport])

  const lineRef = useRef()
  const eyebrowRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const actionRef = useRef()
  const [split, setSplit] = useState()

  useEffect(() => {
    gsap.registerPlugin(SplitText)

    if (!shouldAnimateIn) return

    if (lineRef?.current) {
      gsap.set(lineRef.current, {
        opacity: 0,
        width: "0%",
      })
    }

    if (eyebrowRef?.current) {
      gsap.set(eyebrowRef.current, {
        opacity: 0,
        y: "1em",
      })
    }

    if (titleRef?.current) {
      const q = gsap.utils.selector(titleRef.current)
      const s = new SplitText(q(".g-heading-tag"), {
        linesClass: "line",
        type: "lines, words",
      })
      s.lines.forEach(line => {
        const [divs, otherTags] = separateChildrenFromLine(line)
        if (divs.length > 0) {
          gsap.set(divs, {
            opacity: 0,
            y: "1em",
          })
        }
        if (otherTags.length > 0) {
          gsap.set(otherTags, {
            display: "inline-block",
            marginRight: "-0.15em",
            opacity: 0,
            y: "1em",
          })
        }
      })
      setSplit(s)
    }

    if (textRef?.current) {
      gsap.set(textRef.current, {
        opacity: 0,
        y: "1em",
      })
    }

    if (actionRef?.current) {
      gsap.set(actionRef.current, {
        opacity: 0,
        y: "1em",
      })
    }
  }, [setSplit, shouldAnimateIn, eyebrowRef])

  // Stagger in elements of the text lockup
  useEffect(() => {
    if (!shouldAnimateIn || split === undefined || hasEnteredViewport === false)
      return

    gsap.set(".card-prep", { opacity: 1 })

    const delay = 0.033
    const duration = longAnim
    let delayCount = 0

    // Line (if used)
    if (lineRef?.current) {
      gsap.to(lineRef.current, {
        duration: duration * 2,
        ease: Expo.easeOut,
        opacity: 1,
        width: "100%",
      })
    }

    // Eyebrow (if used)
    if (eyebrowRef?.current) {
      gsap.to(eyebrowRef.current, {
        delay: delay * delayCount,
        duration: duration,
        ease: easeOut,
        opacity: 1,
        y: 0,
      })

      delayCount = delayCount + 4
    }

    // Title
    split.lines.forEach((line, index) => {
      const [divs, otherTags] = separateChildrenFromLine(line)

      if (otherTags.length > 0 || divs.length > 0) {
        const tags = otherTags.concat(divs)
        const revert = () => {
          window.addEventListener("resize", () => split.revert(), {
            once: true,
          })
        }
        tags.forEach(tag => {
          tag.innerHTML = tag.innerHTML.trim()
        })
        gsap.to(tags, {
          delay: delay * (delayCount + index),
          duration: duration,
          ease: easeOut,
          onComplete: revert,
          opacity: 1,
          y: 0,
        })
      }
    })

    delayCount = delayCount + split.lines.length

    // Text (if used)
    if (textRef?.current) {
      gsap.to(textRef.current, {
        delay: delay * delayCount,
        duration: duration,
        ease: easeOut,
        opacity: 1,
        y: 0,
      })

      delayCount = delayCount + 1
    }

    // Action (if used)
    if (actionRef?.current) {
      gsap.to(actionRef.current, {
        delay: delay * delayCount,
        duration: duration,
        ease: easeOut,
        opacity: 1,
        y: 0,
      })
    }
  }, [hasEnteredViewport, shouldAnimateIn, split, textRef, eyebrowRef])

  return (
    <div
      className={`g-card g-card-halign-${hAlign} g-card-valign-${vAlign}`}
      ref={ref}
    >
      {media && <Image {...media} />}
      <div
        className={renderClasses("g-card-content", [
          [shouldAnimateIn, "card-prep"],
        ])}
      >
        {!removeLine && <div className="the-line" ref={lineRef} />}
        {eyebrow && <Eyebrow {...eyebrow} ref={eyebrowRef} />}
        {title && <Title {...title} ref={titleRef} />}
        {text && <Text ref={textRef} text={text} />}
      </div>
      {action && <Action {...action} ref={actionRef} />}
    </div>
  )
}

Card.props = {
  action: PropTypes.shape(Action.props),
  eyebrow: PropTypes.shape(Eyebrow.props),
  hAlign: PropTypes.oneOf(["left", "center", "right"]),
  media: PropTypes.shape(Image.props),
  removeLine: PropTypes.bool,
  shouldAnimateIn: PropTypes.bool,
  text: PropTypes.shape(Text.props),
  title: PropTypes.shape(Title.props),
  vAlign: PropTypes.oneOf(["top", "center", "bottom"]),
}

Card.propTypes = Card.props

export default Card
