import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Track from "./Track"
import "./video.scss"

const Video = ({
  aspectRatio,
  files,
  height = "100%",
  hideControls = false,
  onEnd,
  onPause,
  onPlay,
  tracks,
  width = "100%",
  ...props
}) => {
  const ref = useRef(null)

  // see here for more events:
  // https://mzl.la/3IMDWmQ
  useEffect(() => {
    if (ref.current) {
      if (onEnd) {
        ref.current.onended = onEnd
      }
      if (onPause) {
        ref.current.onpause = onPause
      }
      if (onPlay) {
        ref.current.onplay = onPlay
      }
    }
  }, [onEnd, onPause, onPlay, ref])

  return (
    <video
      {...props}
      className="g-media-video"
      controls={!hideControls}
      height={height}
      style={{ aspectRatio: aspectRatio }}
      width={width}
    >
      {files.map((file, i) => (
        <source key={i} src={file} type={`video/${file.split(".").pop()}`} />
      ))}
      {/* tracks are for making captions available for accessability */}
      {tracks?.map((track, i) => (
        <Track {...track} key={i} />
      ))}
    </video>
  )
}

Video.props = {
  aspectRatio: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.string,
  hideControls: PropTypes.bool,
  onEnd: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  poster: PropTypes.string,
  tracks: PropTypes.arrayOf(PropTypes.shape(Track.props)),
  width: PropTypes.string,
}

Video.propTypes = Video.props

export default Video
