import React from "react"
import PropTypes from "prop-types"
import Layout from "components/blocks/layout"
import Media from "components/blocks/media"
import "./splitSection.scss"
import { renderClasses } from "utils/renderClasses"

/* Split Section */
/**
 * Section with content on the left and media on the right.
 */
const SplitSection = ({
  backgroundColor,
  className,
  image,
  intro,
  reverse,
}) => (
  <Layout
    className={renderClasses(`g-split-section ${className}`, [
      [backgroundColor, `--${backgroundColor}`],
    ])}
    layout="50-50"
    mobileLayout="ACB"
    reverse={reverse}
    textLockup={intro}
  >
    <div className="g-split-section__container">
      <Media {...image} />
    </div>
  </Layout>
)

SplitSection.props = {
  backgroundColor: PropTypes.oneOf([
    "blue",
    "darkGreen",
    "green",
    "yellow",
    "pink",
    "teal",
    "ivory",
    "darkBlue",
    "darkPink",
  ]),
  className: PropTypes.string,
  image: PropTypes.shape(Media.props),
  intro: PropTypes.shape(Layout.props),
  reverse: PropTypes.bool,
}

SplitSection.propTypes = SplitSection.props

export default SplitSection
