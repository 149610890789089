import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Player, Controls } from "@lottiefiles/react-lottie-player"
import "./lottie.scss"

const Lottie = ({
  autoplay = false,
  background = "transparent",
  controls = false,
  count,
  debug = false,
  direction = 1,
  file,
  hover = false,
  keepLastFrame = true,
  loop = false,
  onEvents,
  speed = 1,
}) => {
  const lottieRef = useRef(null)
  const playerRef = useRef(null)

  /**
   * Player Methods:
   * - pause()
   * - play()
   * - stop()
   * - setPlayerDirection(direction: 1 | -1)
   * - setPlayerSpeed(speed?: number)
   * - setSeeker(frame: number | string, play: boolean)
   *
   * Example:
   * playerRef.current.play()
   */

  /**
   *
   * Event options:
   * - load
   * - error
   * - ready
   * - play
   * - pause
   * - stop
   * - freeze
   * - loop
   * - complete
   * - frame
   *
   * Example:
   * if (event === "loaded") {
   *   // loaded
   * }
   */
  const eventHandler = event => {
    if (onEvents) onEvents(event, playerRef.current, lottieRef.current)
  }

  return (
    <div className="g-media-lottie">
      <Player
        autoplay={autoplay}
        background={background}
        controls={controls}
        direction={direction}
        hover={hover}
        keepLastFrame={keepLastFrame}
        loop={count || loop || false}
        lottieRef={lottieRef}
        onEvent={eventHandler}
        ref={playerRef}
        speed={speed}
        src={file.url}
      >
        {controls && (
          <Controls
            buttons={["play", "repeat", "frame"]}
            debug={debug}
            visible
          />
        )}
      </Player>
    </div>
  )
}

Lottie.props = {
  autoplay: PropTypes.bool,
  background: PropTypes.string,
  controls: PropTypes.bool,
  count: PropTypes.number,
  debug: PropTypes.bool,
  description: PropTypes.string,
  direction: PropTypes.number,
  file: PropTypes.shape({
    url: PropTypes.string,
  }),
  hover: PropTypes.bool,
  intermission: PropTypes.number,
  keepLastFrame: PropTypes.bool,
  loop: PropTypes.bool,
  onEvents: PropTypes.func,
  speed: PropTypes.number,
}

Lottie.propTypes = Lottie.props

export default Lottie
