import React, { forwardRef } from "react"
import Button from "components/elements/styledButton"
import "./action.scss"

const Action = forwardRef((props, ref) => (
  <div className="g-card-action" ref={ref}>
    <Button {...props} />
  </div>
))

Action.props = Button.props

Action.displayName = "Action"

Action.propTypes = Action.props

export default Action
