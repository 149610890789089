import { gsap } from "gsap"
import { CustomEase } from "gsap/all"

const shortAnim = 0.165
const mediumAnim = 0.5
const longAnim = 0.83

gsap.registerPlugin(CustomEase)

// Linear
const linear = CustomEase.create("cort-linear", "M0,0,C0,0,1,1,1,1")

// Ease out
const easeOut = CustomEase.create("cort-ease-out", "M0,0,C0,0,0.2,1,1,1")

// Ease in
const easeIn = CustomEase.create("cort-ease-in", "M0,0,C0.8,0,1,1,1,1")

// Ease in out
const easeInOut = CustomEase.create(
  "cort-ease-in-out",
  "M0,0,C0.4,0,0.25,1,1,1"
)

export { linear, easeOut, easeIn, easeInOut, shortAnim, mediumAnim, longAnim }
