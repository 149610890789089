import React, { forwardRef, useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import COMPONENTS from "utils/componentConstants"
import { DEVICE } from "utils/constants"
import { renderClasses } from "utils/renderClasses"
import TextLockup from "components/elements/textLockup"
import MultiColumn from "components/blocks/multiColumn"
import Media from "components/blocks/media"
import Card from "components/blocks/card"
import "./layout.scss"

/**
 * This is a wrapper for common layout patterns that include some form of:
 * text lockup, content module, button container.
 *
 * This can be used as a generalized wrapper for many of the other,
 * more specific components we have.
 */

const Layout = forwardRef(
  (
    {
      actionContent,
      children,
      className,
      component,
      footnoteText,
      layout = "stacked",
      mobileLayout = "ABC",
      reverse,
      textLockup,
      ...props
    },
    ref
  ) => {
    const [isDesktop, setIsDesktop] = useState(true)
    let content

    const checkMedia = useCallback(() => {
      setIsDesktop(window.matchMedia(`(min-width: ${DEVICE.tablet}px)`).matches)
    }, [])

    useEffect(() => {
      const handleResize = () => {
        checkMedia()
      }

      const handleOrientation = () => {
        // After orientationchange, add a one-time resize event
        const afterOrientationChange = () => {
          handleResize()
          // Remove the resize event listener after it has executed
          window.removeEventListener("resize", afterOrientationChange)
        }
        window.addEventListener("resize", afterOrientationChange)
      }

      checkMedia()

      window.addEventListener("orientationchange", handleOrientation)
      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener("resize", handleResize)
        window.removeEventListener("orientationchange", handleResize)
      }
    }, [checkMedia])

    const renderComponent = node => {
      switch (node.__typename) {
        case COMPONENTS.Media:
          return <Media {...node} />

        case COMPONENTS.MultiColumn:
          return (
            <MultiColumn>
              {node?.content?.map((c, i) => (
                <Card {...c} key={i} />
              ))}
            </MultiColumn>
          )

        default:
          return null
      }
    }

    const dynamicContent = !component ? children : renderComponent(component)

    switch (layout) {
      case "stacked":
        content = (
          <div className="layout__container">
            {textLockup && (
              <div className="text-lockup__container">
                <TextLockup
                  {...textLockup}
                  alignment="center"
                  className="text-lockup"
                />
              </div>
            )}
            {dynamicContent && (
              <div className="main-component">{dynamicContent}</div>
            )}
            {actionContent && (
              <div className="button-component__container max-container">
                {actionContent}
              </div>
            )}
          </div>
        )
        break
      case "50-50":
        content = (
          <div
            className={renderClasses("layout__container", [
              [reverse, "--reverse"],
            ])}
          >
            <div className="content-container max-container">
              {textLockup && (
                <div className="text-lockup__container">
                  <TextLockup {...textLockup} className="text-lockup" />
                </div>
              )}
              {dynamicContent && (
                <div className="main-component">{dynamicContent}</div>
              )}
            </div>

            {actionContent && (
              <div className="button-component__container max-container">
                {actionContent}
              </div>
            )}
          </div>
        )
        break
      case "right-middle":
      case "right-top":
        content = (
          <div className="layout__container layout__container--right">
            <div className="text-lockup__container">
              {textLockup && (
                <TextLockup {...textLockup} className="text-lockup" />
              )}
              {actionContent && (
                <div className="button-component__container  max-container">
                  {actionContent}
                </div>
              )}
            </div>
            {dynamicContent && (
              <div className="main-component">{dynamicContent}</div>
            )}
          </div>
        )
        break
      case "left-middle":
      case "left-top":
      default:
        content = (
          <div className="layout__container layout__container--left max-container">
            <div className="text-lockup__container">
              {textLockup && (
                <TextLockup {...textLockup} className="text-lockup" />
              )}
              {actionContent && (
                <div className="button-component__container">
                  {actionContent}
                </div>
              )}
            </div>
            {dynamicContent && (
              <div className="main-component">{dynamicContent}</div>
            )}
          </div>
        )
        break
    }

    const mobileContent = (
      <div className="layout__container layout__mobile">
        {textLockup && (
          <div className="text-lockup__container">
            <TextLockup {...textLockup} className="text-lockup" />
          </div>
        )}
        {dynamicContent && (
          <div className="main-component">{dynamicContent}</div>
        )}
        {actionContent && (
          <div className="button-component__container max-container">
            {actionContent}
          </div>
        )}
      </div>
    )

    const footnote = (
      <div className="footnote max-container">
        <div className="rule">
          <p>{footnoteText}</p>
        </div>
      </div>
    )

    return (
      <section
        {...props}
        className={renderClasses(`layout layout--${layout}`, [
          [className, className],
          [mobileLayout !== "ABC", "layout--mobile-reverse"],
        ])}
        ref={ref}
      >
        {isDesktop ? content : mobileContent}
        {footnoteText && footnote}
      </section>
    )
  }
)

Layout.props = {
  /**
   * Tertiary content area. Meant for interactive elements (like buttons). Can
   * be a mix of HTML elements or React components.
   */
  actionContent: PropTypes.node,
  /**
   * The main content. Can be a mix of HTML elements or React components.
   */
  children: PropTypes.node,
  /**
   * Additional classes for the layout component. Separate with spaces.
   */
  className: PropTypes.string,
  component: PropTypes.node,
  /**
   * Optional text that appears in footer. Makes footer render.
   */
  footnoteText: PropTypes.string,
  layout: PropTypes.oneOf([
    "50-50",
    "left-middle",
    "left-top",
    "right-middle",
    "right-top",
    "stacked",
  ]),
  /**
   * Rearrange components on mobile view.
   */
  mobileLayout: PropTypes.oneOf(["ABC", "ACB"]),
  reverse: PropTypes.bool,
  /**
   * Uses TextLockup component, which also includes the Button component.
   */
  textLockup: PropTypes.shape(TextLockup.props),
}

Layout.displayName = "Layout"

Layout.propTypes = Layout.props

export default Layout
