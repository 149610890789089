import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import "./multiColumn.scss"

/**
 * A wrapper component which presents its children in a 2-, 3- or 4-column
 * layout, which collapses to a single, stacked column on mobile.
 *
 * The layout chosen will depend on the number of children passed into it. If
 * more than four or fewer than two children are passed, the children will be
 * rendered without the column-wrapper.
 */
const MultiColumn = ({ children, className, id, valign = "top" }) => {
  const minCols = 2
  const maxCols = 4
  const count = children.length

  if (count < minCols || count > maxCols) {
    return children
  }

  return (
    <div
      className={renderClasses(
        `multi-column multi-column--cols-${count} multi-column--align-${valign} max-container`,
        [[className, className]]
      )}
      id={id}
    >
      {children}
    </div>
  )
}

MultiColumn.props = {
  /**
   * The column content. Can be a mix of HTML elements or React components.
   */
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  /**
   * Sets the vertical alignment for the columns
   */
  valign: PropTypes.oneOf(["top", "middle", "bottom"]),
}

MultiColumn.propTypes = MultiColumn.props

export default MultiColumn
