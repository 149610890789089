import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import Heading6 from "components/type/Heading6"
import "./eyebrow.scss"

const Eyebrow = forwardRef(({ value }, ref) => (
  <div className="g-card-eyebrow">
    <Heading6 ref={ref}>{value}</Heading6>
  </div>
))

Eyebrow.props = {
  value: PropTypes.string.isRequired,
}

Eyebrow.displayName = "Eyebrow"

Eyebrow.propTypes = Eyebrow.props

export default Eyebrow
