import React from "react"
import PropTypes from "prop-types"
import "./image.scss"

const Image = ({
  alt,
  aspectRatio,
  description,
  file,
  height,
  onLoad,
  src,
  title,
  width = "100%",
}) => (
  <img
    alt={alt || title || description}
    className="g-media-image"
    height={height}
    onLoad={onLoad}
    src={src || file?.url}
    style={{ aspectRatio: aspectRatio }}
    width={width}
  />
)

Image.props = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.string,
  description: PropTypes.string,
  file: PropTypes.shape({
    url: PropTypes.string,
  }),
  height: PropTypes.string,
  onLoad: PropTypes.func,
  src: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
}

Image.propTypes = Image.props

export default Image
