// We have to separate out the div's that SplitText creates
// with the other tags (<u>, <i>, <b> etc) in order to animate
export const separateChildrenFromLine = line => {
  const divs = []
  const otherTags = []
  const children = [...line.children]

  children.forEach(child => {
    if (child.tagName !== "DIV") {
      otherTags.push(child)
    } else {
      divs.push(child)
    }
  })

  return [divs, otherTags]
}
