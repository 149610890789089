import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import Asset from "../Asset"
import "./sticker.scss"

const Sticker = ({
  offsetX = "0",
  offsetY = "0",
  position,
  round = 0.5,
  ...props
}) => (
  <div
    className={renderClasses("g-media-sticker", [[position, `--${position}`]])}
    style={{
      transform: `translateY(${offsetY}) translateX(${offsetX})`,
    }}
  >
    <Asset {...props} round={round} />
  </div>
)

Sticker.props = {
  ...Asset.props,
  offsetX: PropTypes.string,
  offsetY: PropTypes.string,
  position: PropTypes.oneOf([
    "bottomLeft",
    "bottomRight",
    "topLeft",
    "topRight",
  ]),
}

Sticker.propTypes = Sticker.props

export default Sticker
