import React from "react"
import Accordion from "components/sections/accordion"
import "./faq.scss"

const Faq = ({ action, intro, questions }) => (
  <Accordion
    action={action}
    cards={questions}
    className="g-faq"
    intro={intro}
  />
)

Faq.props = Accordion.props

Faq.propTypes = Faq.props

export default Faq
