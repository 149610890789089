import React, { forwardRef } from "react"
import Wysiwyg from "components/type/Wysiwyg"
import "./text.scss"

const Text = forwardRef(({ text, type }, ref) => (
  <div className="g-card-text">
    <Wysiwyg ref={ref} text={{ ...text, type }} />
  </div>
))

Text.props = Wysiwyg.props

Text.displayName = "Text"

Text.propTypes = Text.props

export default Text
