import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { renderRichText } from "utils/renderRichText"
import { renderClasses } from "utils/renderClasses"

const Title = forwardRef(({ className, text }, ref) => (
  <div className={renderClasses("g-title", [[className, className]])} ref={ref}>
    {renderRichText(text)}
  </div>
))

Title.props = {
  className: PropTypes.string,
  text: PropTypes.shape({
    json: PropTypes.object,
  }),
}

Title.displayName = "Title"

Title.propTypes = Title.props

export default Title
