import React from "react"
import Ico from "components/elements/Icon"
import "./icon.scss"

const Icon = props => <Ico {...props} className="g-media-icon" />

Icon.props = Ico.props

Icon.propTypes = Icon.props

export default Icon
