import React from "react"
import PropTypes from "prop-types"

const Track = ({ isDefault = false, kind = "subtitles", src, srclang }) => (
  <track default={isDefault} kind={kind} src={src} srcLang={srclang} />
)

Track.props = {
  isDefault: PropTypes.bool,
  kind: PropTypes.oneOf([
    "subtitles",
    "captions",
    "descriptions",
    "chapters",
    "descriptions",
    "chapters",
    "metadata",
  ]),
  label: PropTypes.string,
  src: PropTypes.string.isRequired,
  srclang: PropTypes.oneOf(["en"]),
}

Track.propTypes = Track.props

export default Track
