import React from "react"
import Media from "components/blocks/media"
import "./image.scss"

const Image = props => (
  <div className="g-card-image">
    <Media {...props} />
  </div>
)

Image.props = Media.props

Image.propTypes = Image.props

export default Image
