import React from "react"
import PropTypes from "prop-types"
import Icon from "../Icon"
import Image from "../Image"
import Video from "../Video"
import Embed from "../Embed"
import Lottie from "../Lottie"
import "./asset.scss"

const Asset = ({
  aspectRatio,
  embed,
  height = "100%",
  icon,
  image,
  lottie,
  radius,
  video,
  width = "100%",
}) => (
  <div
    className="g-media-asset"
    style={{ borderRadius: radius, height: height, width: width }}
  >
    {icon && <Icon {...icon} />}
    {image && (
      <Image
        {...image}
        aspectRatio={aspectRatio}
        height={height}
        width={width}
      />
    )}
    {video && (
      <Video
        {...video}
        aspectRatio={aspectRatio}
        height={height}
        width={width}
      />
    )}
    {embed && <Embed {...embed} />}
    {lottie && <Lottie {...lottie} />}
  </div>
)

Asset.props = {
  aspectRatio: PropTypes.string,
  embed: PropTypes.shape(Embed.props),
  height: PropTypes.string,
  icon: PropTypes.shape(Icon.props),
  image: PropTypes.shape(Image.props),
  lottie: PropTypes.shape(Lottie.props),
  radius: PropTypes.string,
  video: PropTypes.shape(Video.props),
  width: PropTypes.string,
}

Asset.propTypes = Asset.props

export default Asset
