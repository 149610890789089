import React from "react"
import PropTypes from "prop-types"
import "./embed.scss"

const Embed = ({ title, ...props }) => (
  <div className="g-media-embed">
    <iframe {...props} title={title} />
  </div>
)

Embed.props = {
  allow: PropTypes.string,
  allowFullScreen: PropTypes.bool,
  frameBorder: PropTypes.string,
  name: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

Embed.propTypes = Embed.props

export default Embed
